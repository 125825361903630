import {ButtonProps, ButtonType} from './types';
import * as React from 'react';
import '@sass/_button.scss';

const Button = (props: ButtonProps) => {
    const classes = `${props.type ? `button button--${props.type}` : ButtonType.PRIMARY} ${props.large ? 'button--large' : ''}`;
    const buttonText = props.text;
    return (
        <div className={`button-wrapper ${props.className}`}>
            <button
                id={props.id}
                onClick={props.onClick}
                className={classes}
                disabled={props.disabled}
                role={props.role}
                aria-label={props.ariaLabel}>
                <div className={'button__content'}>
                    {buttonText}
                </div>
            </button>
        </div>
    );
}

export default Button;
