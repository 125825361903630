import {AxiosResponse} from "axios";

export enum StatusCode {
    NO_CODE = 0,
    OK = 200,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    INTERNAL_SERVER_ERROR = 500,
}

export interface IFileResponse {
    fileName: string
    file: any
}
export interface ICustomAxiosResponse<T = any> {
    response?: AxiosResponse<T>;
    errors?: any[];
}