import React from 'react'
import Icon from '../Icon'
import { usePantameraService } from "@frontendUtils/pantamera-service";

interface IProps {
  type: string
  message: string
}

const { getCssVariable } = usePantameraService();

const whiteColour = getCssVariable('white');

class GeneralMessage extends React.Component<IProps> {
  render() {
    const { type, message } = this.props
    if (type === 'error') {
      return (
        <div className='general-message general-message--error'>
          <Icon
            iconName="warning"
            size={20}
            color={whiteColour}
            inline={true}
          />
          <div className='text text--error' role="status">
            {message}
          </div>
        </div>
      )
    } else if (type === 'info') {
      return (
        <div className='general-message general-message--info'>
          <Icon iconName="info" size={20} inline={true}/>
          <span className='text text--info' role="status">
            {message}
          </span>
        </div>
      )
    }
    return null
  }
}

export default GeneralMessage