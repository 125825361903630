import {isBrowser} from '@frontendJs/util/helpers'
import {CustomerContext, UserContext} from "@apps/Shared/Context/CustomerContext";
import {CUSTOMER_STATE_CONTEXT, USER_STATE_CONTEXT} from "@frontendUtils/browser-storage";
import UserService from "@apps/Shared/Services/User/User.service";
import {StatusCode} from "@apps/Shared/Services/Response.types";
export function GetCustomerSessionContext(): CustomerContext | null {
    let ctx: CustomerContext | null = null;
    try {
        const data = sessionStorage.getItem(CUSTOMER_STATE_CONTEXT);
        if (data) {
            ctx = JSON.parse(data);
        }
    } catch (e) {
        console.warn('[FG]::', e);
    }
    return ctx;
}

export function GetUserSessionContext(): UserContext | null {
    let ctx: UserContext | null = null;
    try {
        const data = sessionStorage.getItem(USER_STATE_CONTEXT);
        if (data) {
            ctx = JSON.parse(data);
        }
    } catch (e) {
        console.warn(e);
    }
    return ctx;
}
