import axios, {Method, AxiosPromise} from 'axios'
import {isBrowser} from '../../Utils'
import BrowserStorage, {StorageType} from '../../Utils/BrowserStorage'
import {API_URL} from './Api'
import {CUSTOMER_STATE_CONTEXT, LANGUAGE} from "@frontendUtils/browser-storage";
import {DEFAULT_LANGUAGE} from "@apps/Shared/Constants/Language";
import i18n from '@apps/Shared/translation/i18n';


const apiClient = axios.create()

apiClient.interceptors.request.use(
    async config => {
        if (!isBrowser) config

        const storage = new BrowserStorage(StorageType.SESSION)
        const langFromStorage = storage && storage.getItem(LANGUAGE)
        const userLanguage =
            i18n.language || langFromStorage || DEFAULT_LANGUAGE

        // CustomerId
        const customerObject = JSON.parse(
            window.sessionStorage.getItem(CUSTOMER_STATE_CONTEXT) ||
            '{}'
        )

        // Header for customerId
        config.headers[
            'x-pm-companyid'
            ] = `${(customerObject.ActiveCustomer &&
            customerObject.ActiveCustomer.id) ||
        ''}`

        // Header for user language
        config.headers['x-pm-langcode'] = `${userLanguage}`

        return config
    },
    error => {
        Promise.reject(error)
    }
)

export const Client = (
    requestMethod: Method,
    url: string,
    data: Record<string, any> = {} // eslint-disable-line @typescript-eslint/no-explicit-any
): AxiosPromise =>
    apiClient({
        method: requestMethod,
        url: `${API_URL}${url}`,
        data: data,
    })

