import React, {useState, FocusEvent} from 'react'
import {useTranslation} from 'react-i18next'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import debounce from 'lodash/debounce'
import uniqueId from 'lodash/uniqueId'
import includes from 'lodash/includes'
import {SearchProps, SearchTheme, SearchColorMode, Result} from './types'
import {SearchResultItem} from '../Shared/Services/Search/interfaces'
import Icon from '@sharedComponents/Icon'
import InputText from '@sharedComponents/InputText'
import {KEYS} from '../Shared/Constants/KeyCodes'
import Button from '@sharedComponents/Button'
import {LinkType} from '@sharedComponents/Links/types'
import InlineLink from '@sharedComponents/Links/InlineLink'

function resultsFromResponse(t: Function, items: SearchResultItem[]): Result[] {
    // Get top 5 results
    items = items.slice(0, 5)
    // Group by category
    const groups = groupBy(items, 'hitCategory')
    // Map to local type
    const groupArray = Object.keys(groups).map(key => {
        return {
            title: t(`search.category.${key}`),
            items: groups[key].map((item: SearchResultItem) => {
                return {
                    title: item.title,
                    path: item.url,
                }
            }),
        }
    }) as Result[]
    // Sort by...
    return sortBy(groupArray, (group: Result) => {
        return group.items
    })
}

function change(value: string, setValue: Function) {
    setValue(value)
}

function search(path: string, value: string) {
    location.href = `${path}?query=${encodeURI(value)}`
}

export const Search = (props: SearchProps) => {
    const {t} = useTranslation()

    const [value, setValue] = useState('')

    const [autoComplete] = useState(() => {
        return debounce(props.onAutoComplete as any, props.debounceT || 500)
    })

    const clear = props.onClear

    const results = resultsFromResponse(t, props.results)

    return (
        <div className={`search-wrapper`}
        >
            <div className='search-wrapper__icon-container'>
                <Icon
                    key={'search-icon'}
                    iconName={'search'}
                    color={'#0094bf'}
                />
            </div>
            <InputText
                className={`--${props.theme || SearchTheme.Default}`}
                id={uniqueId()}
                inputClassName="site-search-input-field"
                value={value}
                autoComplete="on"
                onChange={e => {
                    const value = e.target.value

                    change(value, setValue)

                    if (value.length >= 3) {
                        autoComplete(value)
                    } else {
                        autoComplete.cancel()

                        clear()
                    }
                }}
                onFocus={(e: FocusEvent<HTMLInputElement>) => {
                    e.preventDefault()
                    e.target.focus({preventScroll: true})
                }}
                onKeyUp={(e: any) => {
                    if (e.keyCode === KEYS.enter) {
                        search(props.searchPagePath, value)

                        clear()
                    }
                }}
                placeholder={t('search.placeholder')}
                label={t('search.placeholder')}
                hideLabel={true}
            />
            <div
                className={`search-wrapper__recommendations-container ${results.length <= 0 && props.recommendations.length > 0 ? 'hasRecommendations' : ''}`}>
                <span
                    className={props.mode === SearchColorMode.DarkBackground ? 'dark-mode' : ''}>{t('search.recommendations')}:</span>
                {(props.recommendations || []).map(
                    (title: string, i: number) => {
                        return (
                            <Button
                                key={i}
                                text={title}
                                onClick={() => {
                                    search(props.searchPagePath, title)
                                }}
                            />
                        )
                    }
                )}
            </div>
            <div className={`search-wrapper__result-container 
                ${props.theme != SearchTheme.Default ? '' : 'search-wrapper__result-container--default-theme'} 
                ${results.length > 0 ? 'search-wrapper__result-container--show-results' : ''} 
                `}>
                {results.map((result: Result, i: number) => {
                    return (
                        <React.Fragment key={i}>
                            <div className='search-wrapper__result-header'>{result.title}</div>
                            {result.items &&
                                result.items.map(item => {
                                    const linkType = includes(item.path, `http`)
                                        ? LinkType.Extern
                                        : LinkType.Intern
                                    const hideIcon = !(
                                        linkType === LinkType.Extern
                                    )
                                    return (
                                        <InlineLink
                                            url={item.path}
                                            key={item.path}
                                            text={item.title}
                                            type={linkType}
                                            hideIcon={hideIcon}
                                        />
                                    )
                                })}
                        </React.Fragment>
                    )
                })}
                <Button
                    text={t('search.getResults')}
                    onClick={() => {
                        search(props.searchPagePath, value)

                        clear()
                    }}
                />
            </div>
        </div>
    )
}

export default Search