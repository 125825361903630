export enum ColorTheme {
    Dark = 'Dark',
    Light = 'Light',
};

export const isBrowser = typeof window !== 'undefined';
export const isIE11 = isBrowser && !!navigator.userAgent.match(/Trident\/7\./);

export const inArray = (needle: string, haystack: Array<any>) => {
    return haystack.includes(needle)
};

