import { EnvObject } from './types'
import { environments } from './Environments'
import { isBrowser } from '../../../Utils'
import BrowserStorage, { StorageType } from '../../../Utils/BrowserStorage'
import { ENVIRONMENT_CONFIG } from '@frontendUtils/browser-storage';

/*
 * Provider to get correct urls and settings for the different environments
 * based on host.
 */

export const EnvironmentUrlProvider = () => {
    const env = environments;

    const localStorage = isBrowser
        ? new BrowserStorage(StorageType.LOCAL)
        : null;

    // Get current url from the browser, return empty string if in node
    const currentHostname = isBrowser ? window.location.hostname : '';

    // Check if one of the defined hosts exists in the current url
    function UrlExistsInArray(currentUrl: string): any {
        return env.filter((envItem: any) => {
            if (envItem.host.includes(currentUrl)) {
                return envItem;
            }
        });
    }

    function SaveToLocalStorage(environment: EnvObject) {
        if (localStorage) {
            localStorage.setItem(
                ENVIRONMENT_CONFIG,
                JSON.stringify(environment)
            );
        }
    }

    // Return Config for the env
    function GetEnvironmentConfig(): EnvObject {
        const match = UrlExistsInArray(currentHostname)[0];

        if (!match || (match && !match.env)) {
            throw Error('Missing environment urls.');
        }

        // Save to local storage
        SaveToLocalStorage(match);

        return match;
    }

    return {
        GetEnvironmentConfig,
    }
}

export default EnvironmentUrlProvider();
