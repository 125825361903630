import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { isBrowser } from '../Utils'
import {getLocale} from "@apps/Shared/Utils/Utils";


i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'sv',
        lng:
            isBrowser && getLocale() || 'sv',
        resources: {
            en: {
                translation: require('./en/translations.json'),
            },
            sv: {
                translation: require('./sv/translations.json'),
            },
        },
        debug: process.env.NODE_ENV !== 'production',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

i18n.languages = ['sv']

export default i18n
