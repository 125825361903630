//
// BrowserStorage class
//
// Description: Enables CRUD against session or local storage
// ==========================================================

export enum StorageType {
    SESSION = 'session',
    LOCAL = 'local',
}

class BrowserStorage {
    storage!: Storage

    constructor(storageType: StorageType) {
        if (storageType === StorageType.LOCAL)
            this.storage = window.localStorage
        if (storageType === StorageType.SESSION)
            this.storage = window.sessionStorage
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key)
    }

    setItem(key: string, value: string) {
        this.storage.setItem(key, value)
    }

    removeItem(key: string) {
        return this.storage.removeItem(key)
    }

    clear() {
        this.storage.clear()
    }

    getAllKeys() {
        return Object.keys(this.storage)
    }

    multiGet(keys: string[]) {
        return keys.map(key => [key, this.storage.getItem(key)])
    }

    multiSet(keyValuePairs: string[][]) {
        keyValuePairs.forEach(keyValuePair =>
            this.storage.setItem(keyValuePair[0], keyValuePair[1])
        )
        return keyValuePairs.map(keyValuePair => [
            keyValuePair[0],
            this.storage.getItem(keyValuePair[0]),
        ])
    }
}

export default BrowserStorage
