import * as React from 'react';
import { ChangeEvent } from 'react';
import { InputTextProps } from './types';
import '../../../../Frontend/scss/_input-type-text.scss';

const InputText = ({
    hideLabel = false,
    required = false,
    ...props
}: InputTextProps) => {
    const classes = `input-text__wrapper ${props.hasError ? 'error' : ''}`;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || '';
        // If value only contains white spaces, it should be empty.
        if (!value.replace(/\s/g, '').length) {
            e.target.value = '';
        }
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <div className='input-text'>
            <label 
                className={`input-text__label ${hideLabel ? 'sr-only' : ''}`}
                htmlFor={props.id}
            >
                {props.label}
                {required ? '*' : ''}
            </label>
            <div className={classes}>
                <input
                    value={props.value}
                    id={props.id}
                    onChange={onChange}
                    placeholder={props.placeholder}
                    maxLength={props.maxLength || undefined}
                    onBlur={props.onBlur || undefined}
                    onFocus={props.onFocus || undefined}
                    onKeyUp={props.onKeyUp || undefined}
                    disabled={props.disabled || false}
                    type={props.type || 'text'}
                    autoComplete={props.autoComplete || 'on'}
                />
            </div>
        </div>
    );
}

export default InputText;
