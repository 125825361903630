import {createRoot} from "react-dom/client";
import SearchApp from "@apps/SearchApp/SearchApp";
import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const container = document.getElementById('js-search-app');
const queryClient = new QueryClient();

if (container) {
    const root = createRoot(container);
    root.render(
        <QueryClientProvider client={queryClient}>
            <SearchApp/>
        </QueryClientProvider>
    );
}