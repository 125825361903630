const buttonPrefix = 'button button--'

export enum ButtonType {
    PRIMARY = `${buttonPrefix}primary`,
    OUTLINED = `${buttonPrefix}outlined`,
    SECONDARY = `${buttonPrefix}secondary`,
    LINK = `${buttonPrefix}link`,
    PANTAMERA_PRIMARY = `${buttonPrefix}pantamera-primary`,
    PANTAMERA_SECONDARY =  `${buttonPrefix}pantamera-secondary`,
}

export enum ButtonClass {
    Primary = 0,
    Outlined = 1,
    Secondary = 2,
    Link = 3,
    PantameraPrimary = 4,
    PantameraSecondary = 5
}

export type ButtonProps = {
    id?: string
    text: any
    type?: string | ButtonType
    disabled?: boolean
    className?: string
    loading?: boolean
    onClick: any
    role?: string
    ariaLabel?: string
    large?: boolean
}
