import { EnvObject } from './types'
export const environments: EnvObject[] = [
    {
        env: 'development',
        host: ['www.rp-pm-dev.local'],
        urls: {
            site: 'https://www.rp-pm-dev.local:8000',
            api: 'https://www.rp-pm-dev.local:8000/api',
            // TODO: REMOVE WHEN WE CAN USE THE NEW API
            // api: 'https://wwwapi.rp-pm-dev.local:5031',
            login: 'https://login.www.rp-pm-dev.local:5001',
        },
    },
    {
        env: 'integration',
        host: ['integration.pantamera.nu'],
        urls: {
            site: 'https://integration.pantamera.nu',
            api: 'https://integration.pantamera.nu/api',
            login: 'https://login.www.rp-pm-test.pantamera.nu',
        },
    },
    {
        env: 'preprod',
        host: [
            'www.rp-pm-test.pantamera.nu',
            'content.rp-pm-dev.pantamera.nu',
            'content.rp-pm-test.pantamera.nu',
        ],
        urls: {
            site: 'https://www.rp-pm-test.pantamera.nu',
            api: 'https://wwwapi.rp-pm-test.pantamera.nu',
            login: 'https://login.www.rp-pm-test.pantamera.nu',
        },
    },
    {
        env: 'production',
        host: [
            'www.pantamera.nu',
            'pantamera.nu',
            'content.pantamera.nu',
            'www.rp-pm-prod.pantamera.nu',
        ],
        urls: {
            site: 'https://pantamera.nu',
            api: 'https://wwwapi.rp-pm-prod.pantamera.nu',
            login: 'https://login.www.rp-pm-prod.pantamera.nu',
        },
    },
]
