import React from 'react';
import GeneralMessage from "@apps/Shared/Components/GeneralMessage";
import { createRoot, Root } from "react-dom/client";

// Store the roots for containers
const roots: { [key: string]: Root } = {};

export const renderServerMessage = (type: string, message: string) => {
  const serverMessageContainer = document.getElementById('serverMessageContainer');
  const fullscreenServerMessageContainer = document.getElementById('fullscreenServerMessageContainer');

  if (serverMessageContainer) {
    let root = roots['serverMessageContainer'];
    if (!root) {
      // If root does not exist, create it and store it
      root = createRoot(serverMessageContainer);
      roots['serverMessageContainer'] = root;
    }
    root.render(<GeneralMessage type={type} message={message} />);
  }

  if (fullscreenServerMessageContainer) {
    let root = roots['fullscreenServerMessageContainer'];
    if (!root) {
      // If root does not exist, create it and store it
      root = createRoot(fullscreenServerMessageContainer);
      roots['fullscreenServerMessageContainer'] = root;
    }
    root.render(<GeneralMessage type={type} message={message} />);
  }
};

export const clearServerMessage = () => {
  const serverMessageContainer = document.getElementById('serverMessageContainer');
  const fullscreenServerMessageContainer = document.getElementById('fullscreenServerMessageContainer');

  if (serverMessageContainer) {
    let root = roots['serverMessageContainer'];
    if (!root) {
      root = createRoot(serverMessageContainer);
      roots['serverMessageContainer'] = root;
    }
    root.render(<div></div>);
  }

  if (fullscreenServerMessageContainer) {
    let root = roots['fullscreenServerMessageContainer'];
    if (!root) {
      root = createRoot(fullscreenServerMessageContainer);
      roots['fullscreenServerMessageContainer'] = root;
    }
    root.render(<div></div>);
  }
};
