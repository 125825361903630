
import * as React from 'react'
import IProps from './IProps'
import { isMultiColored, getMultiColoredIcon } from './utils'
import '@sass/_icons.scss'

class Icon extends React.Component<IProps> {
    render() {
        const {
            iconName,
            color,
            size,
            bgColor,
            className =  `${bgColor? 'bgColor' : ''}`,
            inline
        } = this.props
        if (isMultiColored(iconName)) {
            return getMultiColoredIcon(iconName, size || 16)
        }

        const style: React.CSSProperties = {
            fontSize: size || 12,
            minWidth: size || 12,
          };

          const styleBG: React.CSSProperties = bgColor ? {
            backgroundColor: bgColor,
            width: (size || 12) * 2,
            height: (size || 12) * 2,
            paddingTop: (size || 12) / 2.05,
          } : {};

          const combinedStyle: React.CSSProperties = {
            ...style,
            ...styleBG,
              color: color
          };

        return (
            <span
                style={combinedStyle}
                className={`icon-${iconName} ${className} panicon ${inline ? 'inline' : ''}`}
                aria-hidden={true}
            ></span>
        )
    }
}

export default Icon
